@font-face {
  /* src: url(${Satoshi_400}) format('truetype'); */
  font-weight: 400;
  font-style: normal;
}

  
.select-search-container {
  width: 100%;
  position: relative;
  color: #ffffff;
  box-sizing: border-box;
  margin-top: 15px;
}


.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #eeeeee;
  color: #000;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 10px 10px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-select {
  background: #FFFFFF;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  /* border: 2px solid 'transpar'; */
  overflow: auto;
  max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 10px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: #eeeeee;
}

.select-search-options {
  list-style: none;
  padding: 15px;
  margin: 0;
  border: 1px solid #eeeeee;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  background: #FFFFFF;
  border: none;
  outline: none;
  color: #000;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: #234edb;
}

.select-search-is-selected {
  font-weight: bold;
  color: #234edb;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

.select-search-select {
  display: none;
}

/* SHOW CONAINER IF FOCUSED */
.select-search-has-focus .select-search-select {
  display: block !important;
}