html, body {
    /* background-color: red !important; */
}
/* input[type="date"] {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  line-height: normal !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
} */

.password-strength-bar > div > div {
    height: 4px !important;
}

*:focus {
    outline: none;
}
*:focus-visible {
    outline: none;
}

ul li p {
  margin-bottom: 8px
}

.tiptap a,
.help-article a {
  text-decoration: none!important;
}

.tiptap {
  font-family: "Poppins_400Regular"
}



input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.show-more-text{
  font-family: "Poppins_600SemiBold";
  font-size: .85rem;
}

@media (max-width: 960px) {
  .show-more-text {
    font-size: .75rem;
  }
}

.show-more-less-clickable{
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Inter_700Bold'
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */


/* input[type="time"] {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  line-height: normal !important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
} */




/* Style scrolls for light mode*/

.r-touchAction-1otgn73:hover {
    opacity: 0.75 !important;
}

a:hover {
  opacity: 0.75 !important;
}

.r-padding-1uu6nss {
  padding: .5px !important;
}
